import ReviewForm from './inc/ReviewForm';

if (document.querySelector('#tabs') !== null) {
	new CBPFWTabs(document.getElementById('tabs'));

	$('#gallery').slick({
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		dots: true,
		centerMode: true,
  		variableWidth: true,
		autoplay: true,
		focusOnSelect: true,
		adaptiveHeight: true,
		lazyLoad: 'ondemand',
	});

	new ReviewForm('#reviewForm', `/reviews/${window.pageData.bizSlug}`, 'reload');
}
