import 'select2';
import Bloodhound from 'bloodhound-js';
import 'typeahead.js';

const $category = $('#banner-search');
const $homeSearch = $('#home-search .typeahead');
$category.select2({
	placeholder: 'Select Category',
	ajax: {
		url: `${baseURL}/categories/list`,
		dataType: 'json',
		delay: 250,
		processResults: res => {
			return {
				results: res.map(item => {
					return {
						id: item.id,
						text: item.name,
					};
				}),
			};
		},
		cache: true,
	},
});

const searchBusiness = new Bloodhound({
	datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
	queryTokenizer: Bloodhound.tokenizers.whitespace,
	prefetch: `${baseURL}/business-autocomplete`,
	remote: {
		url: `${baseURL}/business-autocomplete?q=%QUERY&c=%CID`,
		wildcard: '%QUERY',
		replace(url, query) {
			return url.replace('%QUERY', query).replace('%CID', $category.select2('val') || '');
		},
		filter(posts) {
			if (Object.prototype.hasOwnProperty.call(posts, 'q')) return [];

			return $.map(posts, item => ({
				value: item.name,
				text: item.name,
				img: item.logo,
				ratings: item.average_rating,
				category: item.category,
			}));
		}
	}
});

const ratinTem = star => {
	let stars = '';
	for (let i = 1; i <= star; i++) {
		stars += `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="more-star" width="36.438" height="35.666" viewBox="0 0 36.438 35.666">
		<defs>
			<linearGradient id="linear-gradient" x1="0.286" y1="0.856" x2="0.893" y2="0.315" gradientUnits="objectBoundingBox">
			<stop offset="0" stop-color="#00dbf3"/>
			<stop offset="1" stop-color="#248bfa"/>
			</linearGradient>
			<filter id="ic_star_24px" x="0" y="0" width="36.438" height="35.666" filterUnits="userSpaceOnUse">
			<feOffset dy="3" input="SourceAlpha"/>
			<feGaussianBlur stdDeviation="3.5" result="blur"/>
			<feFlood flood-opacity="0.161"/>
			<feComposite operator="in" in2="blur"/>
			<feComposite in="SourceGraphic"/>
			</filter>
		</defs>
		<g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#ic_star_24px)">
			<path id="ic_star_24px-2" data-name="ic_star_24px" d="M9.719,13.787l4.77,2.879L13.223,11.24l4.215-3.651-5.55-.471L9.719,2,7.55,7.118,2,7.589,6.215,11.24,4.949,16.666Z" transform="translate(8.5 5.5)" fill="url(#linear-gradient)"/>
		</g>
		</svg>`;
	}
	return stars;
}

const textHighlighter = (text, query) => {
	const keywords = query.split(/(\s+)/).filter( e => e.trim().length > 0);
	const keysObj = {};
	for (let i=0; i<keywords.length; i++) {
		keysObj[keywords[i]] = keywords[i];
	}
	const regex = new RegExp(keywords.join('|'), 'gi');
	return text.replace(regex, matched => (`<span class="text-dark">${keysObj[matched.toLowerCase()]}</span>`));
};

$homeSearch.typeahead(null, {
	name: 'search-company',
	display: 'value',
	limit: 10,
	source: searchBusiness.ttAdapter(),
	templates: {
		empty: [
			`<div class="empty-message">`,
			`No Results Found!`,
			`</div>`
		].join('\n'),
		suggestion: data => {
			return `
			<div class="media">
				<img src="${baseURL}/${data.img}" width="64" class="mr-3 rounded-circle" alt="${data.text}">
				<div class="media-body text-left">
					<h6 class="mt-0 mb-0">${textHighlighter(data.text, data._query)}</h6>
					<span class="biz-category">${textHighlighter(data.category, data._query)}</span>
					<strong class="float-right" style="font-size:1.1rem; color:#667688">
						${data.ratings}/<small>5</small> 
					</strong>
					<span>${ratinTem(data.ratings)}</span>
				</div>
			</div>`;
		},
	}
});
